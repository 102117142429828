function Footer () {
    return(
       <footer className="footer has-background-light">
           <div className="content has-text-centered">
               <p>
                   <strong>GibCalc</strong> is here to guide your first steps in moving to Gibraltar. If you provide a service in Gibraltar and would like to discuss helping our users with your information <a href="mailto:partners@gibcalc.com">contact us</a>!
               </p>
           </div>
       </footer>
    )
   }
   
export default Footer