function Calculate({expenses, income}){
  
    function totalExpenses(expenses){
        if(isNaN(expenses)){
            return 0;
        }
        return expenses;
    }
    function netIncome(expenses, income){
        if(isNaN(expenses)|isNaN(income)){
            return 0;
        }
        return Number(income)-Number(expenses);
    }

    return(
        <div className='box content is-medium mt-6'>
            <p className="has-text-centered">Your standard monthly expenses total:<strong> £{totalExpenses(expenses).toFixed(2)}</strong></p>
            <p className="has-text-centered">You will have a total of <strong>£{netIncome(expenses,income).toFixed(2)}</strong> disposable income per month.</p>
            <br></br>
        </div>
    );
}

export default Calculate