import { useState } from 'react';
import Footer from './components/Footer'
import Nav from './components/Nav'
import RightContent from './components/RightContent';
import Calculate from './components/Calculate';

const App = ()=>{
    const [income, setIncome] = useState();
    const [rent, setRent] = useState();
    const [food, setFood] = useState();
    const [water, setWater] = useState();
    const [electricity, setElectricity] = useState();
    const [mobiles, setMobiles] = useState();
    const [internet, setInternet] = useState();
    const [insurance, setInsurance] = useState();
    const [subscriptions, setSubscriptions] = useState();
    const [debt, setDebt] = useState();
    const [savings, setSavings] = useState();
    const [expenses, setExpenses] = useState();


    const handleSubmit = (event)=>{
        event.preventDefault();
        setExpenses(
            Number(rent)
            +Number(food)
            +Number(water)
            +Number(electricity)
            +Number(mobiles)
            +Number(internet)
            +Number(insurance)
            +Number(subscriptions)
            +Number(debt)
            +Number(savings)
            );
        const results = document.getElementById("results");
        results.scrollIntoView();
    }
    const handleIncomeChange = (event)=>{
        setIncome(event.target.value);
    }
    const handleRentChange = (event)=>{
        setRent(event.target.value);
    }
    const handleFoodChange = (event)=>{
        setFood(event.target.value);
    }
    const handleWaterChange = (event)=>{
        setWater(event.target.value);
    }
    const handleElecChange = (event)=>{
        setElectricity(event.target.value);
    }
    const handleMobilesChange = (event)=>{
        setMobiles(event.target.value);
    }
    const handleInternetChange = (event)=>{
        setInternet(event.target.value);
    }
    const handleInsuranceChange = (event)=>{
        setInsurance(event.target.value);
    }
    const handleSubscriptionsChange = (event)=>{
        setSubscriptions(event.target.value);
    }
    const handleDebtChange = (event)=>{
        setDebt(event.target.value);
    }
    const handleSavingsChange = (event)=>{
        setSavings(event.target.value);
    }

    return (
    <div className='has-background-light'>
        <Nav />
        <section className="hero is-small is-info">
            
            <div className="hero-body">
                <p className="title has-text-centered">
                Renting in Gibraltar: Monthly Calculator
                </p>
            </div>
        </section>
            <div className='columns'>
                <div className='column is-1'>
                </div>
                <div className='column is-5'>
                    <div className='box mt-3'>
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Income</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control has-icons-left">
                                                <input className="input is-small" autoFocus value={income} name="income" type="number" step="0.01" onChange={handleIncomeChange} />
                                                <span className="icon is-small is-left">
                                                    <i className="fas fa-solid fa-sterling-sign"></i>
                                                </span>
                                            </div>
                                            <p className="help">Enter your NET monthly income. If you're looking for a new Job try <a href="https://www.recruitgibraltar.com/jobs-in-gibraltar" target="_blank" rel="sponsored">Recruit Gibraltar.</a></p>
                                        </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Rent</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={rent} name="rent" type="number" step="0.01"onChange={handleRentChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">You can find great rental properties at <a href="https://www.propertygibraltar.com/search?category=1&order=&agent=&sale=0&price_from=&price_to=&beds=0&baths=0&interior=0&exterior=0" target="_blank" rel="sponsored">PropertyGibraltar.com</a>. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Food</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={food} name="food" type="number" step="0.01"onChange={handleFoodChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">If you live in the U.K. now, add 10% to your current spend.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Water</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={water} name="water" type="number" step="0.01"onChange={handleWaterChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">Water in Gibraltar is supplied by <a href="https://www.aquagib.gi/" target="_blank" rel="sponsored">AquaGib</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Electricity</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={electricity} name="electricity" type="number" step="0.01"onChange={handleElecChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">Electricity in Gibraltar is supplied by the <a href="https://www.gibelec.gi/your-home/energy-efficiency-in-the-home" target="_blank" rel="sponsored"> Gibraltar Electricity Authority</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Mobile Phones</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={mobiles} name="mobiles" type="number" step="0.01"onChange={handleMobilesChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">Get a great deal on local handsets and data from <a href="https://www.gibtele.com/" target="_blank" rel="sponsored">Gibtele</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Internet / Wi-Fi</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={internet} name="internet" type="number" step="0.01"onChange={handleInternetChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">Get the full fibre internet on the Rock from <a href="https://www.gibfibre.com/" target="_blank" rel="sponsored">GibFibre</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Insurance</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={insurance} name="insurance" type="number" step="0.01"onChange={handleInsuranceChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">From vehicle, to personal, and the whole home. <a href="https://masbro.gi/" target="_blank" rel="sponsored">Trust Masbro </a> for your Insurance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Subscriptions</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={subscriptions} name="subscriptions" type="number" step="0.01"onChange={handleSubscriptionsChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">Join Gibraltar's best gym for personal training, classes, yoga, and more at <a href="https://oceanvillagehealthclub.gi/" target="_blank" rel="sponsored">Ocean Village Health Club</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Debt</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={debt} name="debt" type="number" step="0.01"onChange={handleDebtChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">Include all of your monthly payments for Credit Cards and Loans.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">Savings Contributions</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control has-icons-left">
                                            <input className="input is-small" value={savings} name="saving" type="number" step="0.01"onChange={handleSavingsChange} />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-solid fa-sterling-sign"></i>
                                            </span>
                                        </div>
                                            <p className="help">You can save efficiently in Gibraltar with a personal savings account at <a href="https://www.gsb.gov.gi/services/Ordinary%20Deposit%20Account" target="_blank" rel="sponsored">The Gibraltar Savings Bank</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='field is-grouped is-grouped-centered'>
                                <div className='control'>
                                    <button  className='button is-success is-light is-medium is-outlined is-fullwidth' type="submit">
                                        <span className="icon is-medium">
                                            <i className="fa-solid fa-calculator"></i>
                                        </span>   
                                        <span>Calculate</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
                <div id="results" className='column is-5'>
                    <Calculate expenses={expenses} income={income}/>
                    <RightContent expenses={expenses} rent={rent}/> 
                </div>
                <div className='column is-1'>
                </div>
            </div>
            
            <Footer />
            
        </div>  
              
    );
};


export default App