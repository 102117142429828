function RightContent({rent, expenses}){
    const waterApplication = 32.50;
    const waterDeposit = 27.00;
    const electricityApplication = 32.50;
    const electricityDeposit = 32.50;
    const internetSetup = 60.00;
    const firstMonth = waterApplication+waterDeposit+electricityApplication+electricityDeposit+internetSetup+Number(expenses);
    
    function firstMonthTotal (firstMonth){
        if(isNaN(firstMonth)){
            return 0;
        }
        return firstMonth;
    }

    function rentTest(rent){
        if(isNaN(rent)){
            return "You need to fill the form in!"
        }
        return `£${rent}`;
    }

    return(
        <div>
            <div className='box content'>
                <p>For your first month Renting in Gibraltar you can expect to pay some extra fees. In some cases you may be able to avoid these by continuing services from the current tennant, but we recommend figuring these costs in to have an accurate look at things.</p>
                <ul>
                    <li>1 Months rent as deposit: <strong>{rentTest(rent)}</strong></li>      
                    <li>A refundable deposit for Water supply of <strong>£{waterDeposit.toFixed(2)}</strong> and a <strong>£{waterApplication.toFixed(2)}</strong> application fee.</li>
                    <li>A refundable deposit for Electricity supply of <strong>£{electricityDeposit.toFixed(2)}</strong> and a <strong>£{electricityApplication.toFixed(2)}</strong> application fee.</li>    
                    <li>Depending on which Internet package you pick, you may have a set-up fee of around <strong>£{internetSetup.toFixed(2)}</strong> to pay.</li>
                    <li>This means that you may need to budget at least <strong>£{firstMonthTotal(firstMonth).toFixed(2)}</strong> for your first month in Gibraltar</li>           
                </ul>
            </div>
            <div className='box content'>
                <p>If you're considering a long-term move, a short-term let may be your next step. <a href="https://www.chestertons.gi/short-let" target="_blank" rel="external">Try Chestertons Short-Term Lets</a>.</p>
            </div>
        </div>
    );
}

export default RightContent