import logo from './img/GIBCALCgbp.png';


function Nav(){
    return(
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item"> 
                    <img src={logo} width="112" height="28" alt="GibCalc Logo in the style of text on a calculator screen."/> 
                </a>
            </div>
            <div className="navbar-menu">
                <div className="navbar-start">
                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">
                            More Calculators
                        </a>
            
                        <div className="navbar-dropdown">
                            <a className="navbar-item is-active" href="/index.html">
                                Renting Monthly Calculator
                            </a>
                            <a className="navbar-item" href="/index.html">
                                Homeowner Monthly Calculator
                            </a>
                            <a className="navbar-item" href="/index.html">
                                Salary Calculator
                            </a>
                            <hr className="navbar-divider" />
                            <a className="navbar-item" href="mailto:suggestions@gibcalc.com">
                                Make a Suggestion
                            </a>
                            <hr className="navbar-divider" />
                            <a className="navbar-item" href="mailto:partner@gibcalc.com">
                                Partner as a Business
                            </a>
                        </div>
                    </div>
                </div>
                <div className="navbar-end">
                    
                    <p className="navbar-item" >
                        Take a risk, but make it a Calculated one. 
                    </p>
                </div>
            
            
                
            </div>
        </nav>
    );
}

export default Nav